function mobileSoc() {
  const ms = document.querySelector('.mobile-social');
  const btn = document.querySelector('.mobile-social__btn');
  const links = document.querySelectorAll('.mobile-social .social__link');

  btn.addEventListener('click', toggleSocial);
  links.forEach(el => el.addEventListener('click', hideSocial))

  function toggleSocial() {
    ms.classList.toggle('--is-open');
  }

  function hideSocial() {
    ms.classList.remove('--is-open');

  }
}

mobileSoc()
function postInit() {
  const postBody = document.querySelector('.post__body');
  const imagesLeft = postBody.querySelectorAll('figure[data-orient="left"]>img');
  const imagesRight = postBody.querySelectorAll('figure[data-orient="right"]>img');

  let parLeft = new simpleParallax(imagesLeft, {
    scale: 2,
    delay: 0.1,
    transition: "ease-out",
    overflow: true,
    orientation: "right"
  })
  let parRight = new simpleParallax(imagesRight, {
    scale: 2,
    delay: 0.1,
    transition: "ease-out",
    overflow: true,
    orientation: "left"
  })
}

if (document.querySelector('.post')) {
  postInit()
}
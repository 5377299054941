function burger() {
  const burger = document.querySelector('.topbar__burger');
  const menu = document.querySelector('.topbar__menu');
  const menuLinks = document.querySelectorAll('.menu__link');
  const body = document.body;

  burger.addEventListener('click', toggleMenu);
  menuLinks.forEach(el => el.addEventListener('click', () => {
    if (window.matchMedia('(max-width: 1023px)').matches) {
      toggleMenu()
    }
  }))

  function toggleMenu() {
    if (!burger.classList.contains('--is-open') && !menu.classList.contains('--is-open')) {
      burger.classList.add('--is-open');
      menu.classList.add('--is-open');
      menu.style.display = "flex";
      body.style.maxHeight = "100vh";
      body.style.overflowY = "hidden";
      setTimeout(() => {
        menu.style.transform = 'translateY(0)'
      }, 1);
    } else {
      burger.classList.remove('--is-open');
      menu.classList.remove('--is-open');
      menu.style.display = "none";
      body.style.maxHeight = "unset";
      body.style.overflowY = "auto";
      setTimeout(() => {
        body.removeAttribute('style')
        menu.removeAttribute('style')
      }, 100);
    }
  }
}

burger();
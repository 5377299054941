AOS.init();

function getPage() {
  let location = window.location.pathname.replace("/", "");
  const locationList = {
    "": "Головна",
    "index_short.html": "Коротка головна",
    "blog.html": "Блог",
    "post-1.html": "Пост блогу",
    "thank_you.html": "Сторінка подяки",
    "product-1.html": "Сторінка 'Альтанка'",
    "product-2.html": "Сторінка 'Тераса'",
    "product-3.html": "Сторінка 'Веранда'",
    "product-4.html": "Сторінка 'Кафе'"
  }
  if (locationList[location]) {
    return locationList[location];
  }
  return "Не вдалося визначити сторінку або сторінки немає в списку"
}
function bulletsShowLink() {
  const buttons = document.querySelectorAll('.bullets__item');
  const links = document.querySelectorAll('.bullets__item-link');
  const items = document.querySelectorAll('.bullets__item-wrapper');

  buttons.forEach(el => el.addEventListener('click', showLink))

  links.forEach(el => el.addEventListener('click', hideLink))

  function showLink(e) {
    items.forEach(el => el.classList.remove('--is-active'));
    e.currentTarget.parentNode.classList.toggle('--is-active');
  }

  function hideLink(e) {
    items.forEach(el => el.classList.remove('--is-active'));
    e.currentTarget.parentNode.classList.remove('--is-active')
  }
}

bulletsShowLink();
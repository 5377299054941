function priceFormFunc() {
  const form = document.querySelector('.price__form');

  if (form) form.addEventListener("submit", sendPrice)

  function sendPrice(e) {
    e.preventDefault();

    let formData = {
      "Час": new Date(),
      "Форма": "Каталог",
    };
    formData['Месенджер'] = document.querySelector('.price__radio-input[name="messenger"]:checked').id;
    formData["Телефон"] = document.querySelector('.price__input').value;
    formData["Сторінка"] = getPage()
    sendData(formData);
    form.reset()
  }
}
priceFormFunc()
function bloger() {
  const playButton = document.querySelector('.bloger__play-button');
  const video = document.querySelector('.bloger__video');

  playButton.addEventListener('click', handleButton);

  video.addEventListener('play', handleButton)
  video.addEventListener('pause', onStopVideo);
  video.addEventListener('ended', onStopVideo);

  function handleButton() {
    playButton.classList.add('--hidden');
    video.play()
  }

  function onStopVideo() {
    playButton.classList.remove('--hidden');
  }

}
if (document.querySelector('.bloger')) {
  bloger()
}
function sendData(data) {
  const sendData = new FormData();
  for (const key in data) {
    sendData.append(key, encodeURIComponent(data[key]))
  }
  console.log(sendData);
  axios.post('./sendtg.php', sendData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      // Обробляємо відповідь сервера
      if (!response.data.ok) {
        console.table(response.data);
      }
      thankYou(response.data)
      return true;
    })
    .catch(error => {
      // Обробляємо помилки відправки запиту
      thankYou(error.message);
    })
}

function thankYou(r) {
  window.open(`./thank_you.html${r!==""?"?msg="+r:"Дякуємо за ваш запит.</br> Ми зв'яжемося з Вами найближчим часом!"}`, 'thank you', `
        width= ${window.screen.width},
        height= ${window.screen.height},
        menubar=no,
        toolbar=no,
        location=no,
        status=no,
        resizable=no,
        scrollbars=no
      `)
}
function testimonialSliders() {
  const rem = parseInt(window.getComputedStyle(document.querySelector("html")).fontSize);
  const videoSlider = new Swiper(".testimonials__video-slider .swiper", {
    slidesPerView:1,
      navigation: {
    nextEl: '.testimonials__video-slider .swiper-button-next',
    prevEl: '.testimonials__video-slider .swiper-button-prev',
    },
    pagination: {
      el: '.testimonials__video-slider .swiper-pagination',
      clickable:true
      }
  });
  const googleSlider = new Swiper(".testimonials__google-wrapper .swiper", {
    slidesPerView: 1,
      spaceBetween: 2.5 * rem,
      autoHeight: true,
      navigation: {
    nextEl: '.testimonials__google-wrapper .swiper-button-next',
    prevEl: '.testimonials__google-wrapper .swiper-button-prev',
    }, breakpoints: {
      640: {
        slidesPerView: 2,
        autoHeight: false,

      }
    }
  });
}
testimonialSliders();
function testimonialsVideo() {
  const videos = document.querySelectorAll('.testimonials__video-item');
  const buttons = document.querySelectorAll('.testimonials__play-button');

  buttons.forEach(el => {
    el.addEventListener('click', handleButton)
  })

  videos.forEach(el => {
    el.addEventListener('play', turnOffThisButton)
    el.addEventListener('pause', turnOnThisButton)
    el.addEventListener('enden', pauseAll)
  })

  function turnOffThisButton(e) {
    e.currentTarget.previousElementSibling.classList.add('--hidden')
  }

  function turnOnThisButton(e) {
    e.currentTarget.previousElementSibling.classList.remove('--hidden')
  }

  function handleButton(e) {
    pauseAll()
    e.currentTarget.classList.add('--hidden');
    e.currentTarget.nextElementSibling.play();
  }

  function pauseAll() {
    videos.forEach(el => {
      el.pause()
    })
    buttons.forEach(el => {
      el.classList.remove('--hidden')
    })
  }
}
testimonialsVideo()
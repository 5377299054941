function callback() {

  const callbackEl = document.querySelector('.callback');
  const callbackOpeners = document.querySelectorAll('.callback-open');
  const callbackClosers = document.querySelectorAll('.callback-close');
  const form = document.querySelector('.callback__form');


  callbackOpeners.forEach(el => el.addEventListener('click', openCallback));
  callbackClosers.forEach(el => el.addEventListener('click', closeCallback));
  form.addEventListener("submit", sendform)

  function openCallback() {
    document.querySelector('body').style.overflow = "hidden";
    callbackEl.classList.add('--is-open')
  }

  function closeCallback() {
    document.querySelector('body').style.overflow = "auto";
    callbackEl.classList.remove('--is-open')
  }

  function sendform(e) {
    e.preventDefault()
    let phone = form.querySelector('.callback__input').value;
    const data = {
      "Тип форми": "Передзвоніть за 30 секунд",
      "Телефон": phone,
      "Час": new Date(),
      "Сторінка": getPage()
    }
    sendData(data)
    closeCallback()
  }
}

callback();
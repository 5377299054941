function postHeader() {
  new simpleParallax(document.querySelector('.post-header__pic img'), {
    customWrapper: ".post-header__pic",
    scale: 1.75,
    orientation: "down",
      delay: 0,
      transition: "ease-out",
  })
}
if (document.querySelector('.post-header__pic')) {
  postHeader()
}
function scrollBgVideo ()
{
  const videoBg = document.querySelector('.header__video-inner');

  window.addEventListener('scroll', startStopVideo)
  
  function startStopVideo (e)
  {
    const scrollH = window.innerHeight + 20;

    if (window.scrollY > scrollH) {
      videoBg.pause()
    }
    else {
      videoBg.play()
    }
  }

}
if (document.querySelector('.header__video-inner')) scrollBgVideo()

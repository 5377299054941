  const $slider = document.getElementById("square-num");

  function rangeInit() {

    $slider.addCSS(`
  .value1-label{
    display:none;
  }

  .row{
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .range-slider{
    order:1;
    flex: 0 0 100%;
  }

  .min-label, .max-label{
    order: 2;
    flex: 0 0 auto;
    margin: 1.75rem 0 0;
    width: unset;
  }

  .pointer-shape{
    border: 0.25rem solid var(--color__white);
    border-radius:50%;
    height: 1.25rem;
    width: 1.25rem;
    box-shadow: 0 7px 27px rgba(40, 39, 40, 0.36);
  }
`)
  }

  if ($slider) {
    rangeInit();
  }

  const slidesProgress = document.querySelector('.quiz__slider-progress');
  const allSlides = document.querySelectorAll('.quiz__slides-item');

  function slidesSwitcher() {

    const slide1 = document.querySelector('.quiz__slides-item[data-slide="1"]');
    const slide2 = document.querySelector('.quiz__slides-item[data-slide="2"]');
    const slide3 = document.querySelector('.quiz__slides-item[data-slide="3"]');
    const slide4 = document.querySelector('.quiz__slides-item[data-slide="4"]');
    const slide5 = document.querySelector('.quiz__slides-item[data-slide="5"]');
    const slide6 = document.querySelector('.quiz__slides-item[data-slide="6"]');
    // const slide7 = document.querySelector('.quiz__slides-item[data-slide="7"]');
    const slideFinish = document.querySelector('.quiz__slides-item[data-slide="finish"]');


    const buttonsNext = document.querySelectorAll('.slide__button-next');
    const slideForm = document.querySelector('.slide__finish-wrapper');

    if (!localStorage.quiz) {
      resetData()
    } else {
      let quizData = JSON.parse(localStorage.quiz);
      quizData["Час"] = new Date();
      nextSlide(quizData.currentSlide)
      localStorage.setItem("quiz", JSON.stringify(quizData));
    }

    slide1.querySelectorAll('.slide__check-input').forEach(
      el => el.addEventListener("change", slide1choice)
    )

    slide2.querySelectorAll('.slide__choice').forEach(
      el => el.addEventListener("change", slide2choice)
    )

    slide3.querySelector('.js-slide3-next').addEventListener('click', slide3choice);

    slide4.querySelectorAll('.slide__choice').forEach(
      el => el.addEventListener("change", slide4choice)
    )

    slide4.querySelector('.js-slide4-next').addEventListener('click', slide4input)

    slide5.querySelectorAll('.slide__choice').forEach(
      el => el.addEventListener("change", slide5choice)
    )

    slide6.querySelectorAll('.slide__choice').forEach(
      el => el.addEventListener("change", slide6choice)
    )

    // slide7.querySelectorAll('.slide__check-input').forEach(
    //   el => el.addEventListener("change", slide7choice)
    // )

    slideForm.addEventListener("submit", quizSubmit)

    function slide1choice(e) {
      setLocalStorageQuizData(e.target.name, e.target.value)
      slide1.querySelector('.slide__button-next').disabled = false;
    }

    function slide2choice(e) {
      if (e.target.value === "true") {
        nextSlide(3)
        slide4.querySelector('.slide__button-next').dataset.slide = 3;
      } else {
        setLocalStorageQuizData("Орієнтовна площа", "Невідома")
        slide4.querySelector('.slide__button-next').dataset.slide = 2;
        nextSlide(4)
      }
    }

    function slide3choice() {
      setLocalStorageQuizData($slider.attributes.name.nodeValue, $slider.value)
      console.log($slider.attributes.name.nodeValue);
      console.log($slider.value);
    }

    function slide4choice(e) {
      if (e.target.value !== "Інший варіант" && e.target.type !== "number") {
        setLocalStorageQuizData(e.target.name, e.target.value)
        nextSlide(5)
      } else {
        slide4.querySelector('.slide__button-next[disabled]').removeAttribute('disabled');
        slide4.querySelector('.slide__choice.--hidden').classList.remove('--hidden')
      }
    }

    function slide4input() {
      setLocalStorageQuizData(slide4.querySelector('.slide__choice-num').name, slide4.querySelector('.slide__choice-num').value)
      nextSlide(5)
    }

    function slide5choice(e) {
      setLocalStorageQuizData(e.target.name, e.target.value)
      nextSlide(6)
    }

    function slide6choice(e) {
      setLocalStorageQuizData(e.target.name, e.target.value)
      nextSlide('finish')
    }

    // function slide7choice(e) {
    //   setLocalStorageQuizData(e.target.name, e.target.value)
    //   nextSlide('finish');
    // }

    buttonsNext.forEach(el => {
      el.addEventListener('click', (e) => nextSlide(e.currentTarget.dataset.slide))
    })
  }

  function nextSlide(slideNum) {
    let currentSlide = JSON.parse(localStorage.quiz).currentSlide;
    if (slideNum !== "finish") {
      if (currentSlide > slideNum) {
        document.querySelector(`.quiz__slides-item[data-slide="${slideNum}"]`).querySelectorAll('.slide__check-input, .slide__choice-input').forEach(el => el.checked = false)
      }
      slidesProgress.style = `--progress: ${ slideNum }`;
      slidesProgress.querySelector('.quiz__progress-counter').innerHTML = slideNum;
    } else {
      document.querySelector('.quiz').classList.add('--finished')
    }
    allSlides.forEach(el => {
      el.style.display = "none";
    })
    let quizData = JSON.parse(localStorage.getItem("quiz"));
    quizData.currentSlide = slideNum;
    localStorage.setItem("quiz", JSON.stringify(quizData));
    document.querySelector(`.quiz__slides-item[data-slide="${slideNum}"]`).style.display = "flex"
  }

  function setLocalStorageQuizData(dataKey, dataValue) {
    let quizData = JSON.parse(localStorage.getItem("quiz"));
    quizData[dataKey] = dataValue;
    localStorage.setItem("quiz", JSON.stringify(quizData));
  }
  if (allSlides.length > 0) {
    slidesSwitcher();
  }

  function quizSubmit(e) {
    e.preventDefault();
    let quizData = JSON.parse(localStorage.getItem("quiz"));
    delete quizData.currentSlide;
    quizData["Форма"] = "Квіз";
    quizData["Месенджер"] = document.querySelector('.slide__radio-input[name="messenger"]:checked').id;
    quizData["Телефон"] = document.querySelector('.slide__phone-input').value;
    quizData["Час відправки форми"] = new Date();
    quizData["Сторінка"] = getPage();
    sendData(quizData)
    resetData();
    nextSlide(1);
    document.querySelector('.quiz').classList.remove('--finished')
  }

  function resetData() {
    localStorage.setItem("quiz", JSON.stringify({
      "Час": new Date(),
      currentSlide: 1
    }))
    document.querySelectorAll('.slide__check-input, .slide__choice-input').forEach(el => el.checked = false)
  }
function phoneInputs() {
  const phoneInputs = document.querySelectorAll('input[type="tel"]');

  const telMask = new Inputmask("+38(999)999-99-99", {
    placeholder: "_",
    greedy: true,
    validator: "[0-9]",
    inputmode: "tel",
    onincomplete: function () {
      this.setCustomValidity("Заповніть поле до кінця")
    },
    oncomplete: function () {
      this.setCustomValidity("")
    },
  });

  phoneInputs.forEach(el => {
    telMask.mask(el);
  })
}

phoneInputs();
function blogNoLinks() {
  const links = document.querySelectorAll('.blogs__posts-pic-link, .blogs__posts-link');
  const posts = document.querySelectorAll('.blogs__posts-item');
  const alert = document.querySelector('.blogs__alert');
  const ok_btn = document.querySelector('.blogs__alert-close');
  const timer = document.querySelector('.blogs__alert-timer');
  let time = 0;

  links.forEach(el => {
    if (el) el.addEventListener('click', noLink)
  });
  if (ok_btn) ok_btn.addEventListener('click', hideAlert);

  function startTimer() {
    let timerFunc = setInterval(() => {
      time -= 1;
      timer.innerText = time;
      if (time <= 0) {
        timer.innerText = 10;
        clearInterval(timerFunc)
      }
    }, 1000);
    // timerFunc();
  }

  function noLink(e) {
    e.preventDefault();
    time = 10;
    timer.innerText = time;
    startTimer();
    posts.forEach(el => {
      el.style.opacity = 0.25
      el.querySelector('.blogs__posts-pic').style.transform = "none";
      el.querySelector('.blogs__posts-pic').style.opacity = "0.5";
      el.querySelectorAll('a').forEach(el => el.style.pointerEvents = "none");
      setTimeout(() => {
        el.querySelectorAll('a').forEach(el => el.removeAttribute('style'));
      }, 60000);
    });
    alert.style.opacity = 1;
    alert.style.pointerEvents = "all";

    setTimeout(() => {
      hideAlert()
    }, 10000);
  }

  function hideAlert() {
    alert.removeAttribute('style');
    time = 0;
    timer.innerText = time;
    setTimeout(() => {
      time = 10;
      timer.innerText = time;
    }, 100);
    posts.forEach(el => {
      el.style.opacity = 1
      el.querySelector('.blogs__posts-pic').removeAttribute('style');
    });
  }
}
blogNoLinks();
function products() {
  const productsItems = document.querySelectorAll('.products__item');
  const bulletsLinks = document.querySelectorAll('.bullets__item-link');
  productsItems.forEach(el => {
    el.addEventListener('click', e => toggleSelected(e.currentTarget))
  })

  bulletsLinks.forEach(el => el.addEventListener('click', e =>
    toggleSelected(document.querySelector(e.currentTarget.hash))
  ))

  function toggleSelected(elem) {
    if (!elem.classList.contains('--selected')) {
      productsItems.forEach(el => el.classList.remove('--selected'))
      elem.classList.add('--selected');
    } else {
      productsItems.forEach(el => el.classList.remove('--selected'))
    }
  }
}

products();
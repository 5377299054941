function stepsSwitcher ()
{
  const buttons = document.querySelectorAll('.steps__button');
  const steps = document.querySelectorAll(".step");

  let maxHeight = 0;

  steps.forEach(el =>
  {
    if (el.scrollHeight > maxHeight) {
      maxHeight = el.scrollHeight;
      document.querySelector('.steps__wrapper').style.minHeight = maxHeight + "px";
    }
  })


  buttons.forEach(
    el => { el.addEventListener('click', switchStep) }
  )

  function switchStep (e)
  {
    let step = e.currentTarget.dataset.step;
    const thisStep = document.querySelector(`.step[data-step="${step}"]`);
    if (!e.currentTarget.classList.contains("--is-active")) {
      buttons.forEach(el => { el.classList.remove("--is-active") });
      e.currentTarget.classList.add('--is-active');
      steps.forEach(el =>
      {
        el.style.opacity = 0;
        setTimeout(() => {
          el.classList.remove("--is-active")
          el.removeAttribute("style")
          thisStep.classList.add("--is-active");
          thisStep.style.opacity = 1;
        }, 250);
      })
    }
  }

  let touchstartX = 0
  let touchendX = 0

  function checkDirection(e) {
    let currentStep = parseInt(e.currentTarget.dataset.step);
    let directionStep = touchendX < touchstartX ? currentStep + 1 : currentStep - 1;
    if ((0 < directionStep && directionStep < 6) &&
      ((touchstartX - touchendX > 60) || (touchstartX - touchendX < -60))) {
      buttons.forEach(el => {
        el.classList.remove("--is-active")
      });
      document.querySelector(`.steps__button[data-step="${ directionStep }"]`).classList.add('--is-active');
      document.querySelector(`.steps__button[data-step="${ directionStep }"]`).scrollIntoView({
        block: "center",
        behavior: "smooth"
      })
      steps.forEach(el => {
        el.style.opacity = 0;
        setTimeout(() => {
          el.classList.remove("--is-active")
          el.removeAttribute("style")
          document.querySelector(`.step[data-step="${directionStep}"]`).classList.add("--is-active");
          document.querySelector(`.step[data-step="${directionStep}"]`).style.opacity = 1;
        }, 250);
      })
    }
  }

  steps.forEach(el => {
    el.addEventListener('touchstart', e => {
      touchstartX = e.changedTouches[0].screenX
    })
  })

  steps.forEach(el => {
    el.addEventListener('touchend', e => {
      touchendX = e.changedTouches[0].screenX
      checkDirection(e)
    })
  })

}

stepsSwitcher()